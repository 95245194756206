<template>
  <div class="login-view bg-healex-gradient">
    <b-container>
      <div class="box text-center">
        <b-card class="inner p-2 p-md-5 shadow">
          <simple-loader v-if="loading" />
          <b-form v-if="!loading" @submit="onSubmit()" @reset="onReset">
            <b-card-text class="text-left text-uppercase mb-5">
              <h3>{{ $t("register.title") }}</h3>
            </b-card-text>
            <b-input-group class="mb-3">
              <b-input-group-prepend is-text>
                <b-icon icon="person-fill"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                v-model="form.FullName"
                :placeholder="$t('register.form.name')"
                type="text"
                required
              />
            </b-input-group>
            <b-input-group class="mb-5">
              <b-input-group-prepend is-text>
                {{ "      @      " }}
              </b-input-group-prepend>
              <b-form-input
                v-model="form.Mail"
                :placeholder="$t('register.form.mail')"
                type="email"
                required
              />
            </b-input-group>
            <b-input-group class="mb-5">
              <b-input-group-prepend is-text>
                <b-icon-pencil />
              </b-input-group-prepend>
              <b-form-textarea
                v-model="form.Comments"
                :placeholder="$t('register.form.comment')"
                rows="3"
                max-rows="6"
              />
            </b-input-group>
            <b-row class="mb-3">
              <b-col sm="12" md="6" class="mb-2 mb-md-0">
                <b-button block variant="primary" @click="onSubmit()">{{
                  $t("register.button.send")
                }}</b-button>
              </b-col>
              <b-col sm="12" md="6">
                <b-button block variant="primary" @click="onLeave">{{
                  $t("register.button.back")
                }}</b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "register-view",
  components: {},
  data() {
    return {
      form: {
        FullName: "",
        Mail: "",
        Comments: "",
      },
      show: null,
    };
  },
  watch: {},
  methods: {
    onSubmit() {
      this.$api
        .Register(this.form.FullName, this.form.Mail, this.form.Comments)
        .then((response) => {
          this.toast("Success", response.data.message, true);
        })
        .catch((error) => {
          console.log("Error: ", error);
          this.toast("Error", error.message, false);
        });
    },
    async toast(title, message, leave) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 4000,
        appendToast: true,
      });

      if (leave) {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        this.onLeave();
      }
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.FullName = "";
      this.form.Mail = "";
      this.form.Comments = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onLeave() {
      this.$router.push("/login");
    },
  },
  computed: {
    ...mapGetters({
      loading: "GET_LOADING",
    }),
    ...mapGetters({
      message: "GET_MESSAGE",
    }),
  },
};
</script>

<style scoped>
.login-view {
  position: relative;
  width: 100%;
  height: 100%;
  background: #dedede;
}

.login-view .box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-view .box .inner {
  position: relative;
  width: 500px;
  max-width: 100%;
}

.login-view .helex-log {
  display: block;
  max-width: 200px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
}
</style>
